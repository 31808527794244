import React from 'react';

import { ContactNotesData } from '../../../reusable/AddNotesModal';
import BaseModal from '../../../reusable/BaseModal';
import { TreeCardAvatar } from '../../ViewLandPartnerDetails/LandPartnerRelationship/TreeCard';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    id?: string;
    contact: ContactNotesData;
    avatar?: TreeCardAvatar;
}

export default function FarmTeamNotes({ isShowing, onClose, id, contact }: OwnProps) {
    const headerText = `Notes: ${contact.firstName} ${contact.lastName}`;
    return (
        <BaseModal isShowing={isShowing} onClose={onClose} header={headerText} size={'medium'}>
            <p>Farm Team Notes for contact with {id}</p>
        </BaseModal>
    );
}
