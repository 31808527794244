import React from 'react';
import { toast } from 'react-toastify';

import { Box, IconButton } from '@mui/material';

import { ReactComponent as BrainIcon } from '../assets/icons/brain_icon.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/check_icon.svg';
import { ReactComponent as ErrorCircle } from '../assets/icons/error_circle_icon.svg';
import { ReactComponent as InfoCircle } from '../assets/icons/info_circle_icon.svg';
import logoWhite from '../assets/icons/logo_white_small.svg';
import AppTypography from './AppTypography';

interface OwnProps {
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
}

const toastConfig = {
    info: { color: '#119eca', icon: <InfoCircle /> },
    success: { color: '#248232', icon: <CheckCircle /> },
    warning: { color: '#f9dc5c', icon: <BrainIcon /> },
    error: { color: '#d74242', icon: <ErrorCircle /> },
};

const CustomToast = ({ type, message }: OwnProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '12px',
                color: 'white',
                backgroundColor: toastConfig[type].color,
            }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}>
                <IconButton
                    onClick={() => toast.dismiss()}
                    sx={{
                        color: 'white',
                        fontSize: 16,
                        padding: 0,
                    }}>
                    ✖
                </IconButton>
                <img src={logoWhite} alt="Company Logo" style={{ width: 50, height: 20 }} />
            </Box>

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    columnGap: '12px',
                }}>
                <div
                    style={{
                        flex: 1,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font16'}
                        fontWeight={'regular'}
                        text={message}
                    />
                </div>
                {toastConfig[type].icon}
            </Box>
        </Box>
    );
};

export default function customToastWithAlert({ type, message }: OwnProps) {
    return toast(<CustomToast type={type} message={message} />, {
        theme: 'colored',
        style: { backgroundColor: toastConfig[type].color, color: 'white' },
        autoClose: 5000,
        position: 'top-right',
        closeButton: false,
    });
}
