import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import PATHS from './paths';
import UserPersonalInfo from '../features/UserProfile/UserPersonalInfo';
import FarmProfile from '../features/FarmProfile/FarmProfile';

const LeaseWizard = lazy(() => import('../features/CreateLeaseForm/LeaseWizard'));
const PageWrapper = lazy(() => import('../layouts/PageWrapper/PageWrapper'));
const Communications = lazy(() => import('../pages/Communications/Communications'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const LandPortfolio = lazy(() => import('../pages/Leases/LandPortfolio'));
const LeaseDetailPage = lazy(() => import('../pages/Leases/LeaseDetailPage'));
const ManageLeasesPage = lazy(() => import('../pages/Leases/ManageLeasesPage'));
const Integrations = lazy(() => import('../pages/Settings/Integrations/Integrations'));
const NotificationsAndViews = lazy(() => import('../pages/Settings/NotificationsAndViews'));
const Organizations = lazy(() => import('../pages/Settings/Organizations/Organizations'));
const OrganizationsAndUsers = lazy(() => import('../pages/Settings/OrganizationsAndUsers'));
const Roles = lazy(() => import('../pages/Settings/Roles/Roles'));
const Settings = lazy(() => import('../pages/Settings/Settings'));
const Users = lazy(() => import('../pages/Settings/Users/Users'));
const ForgotPasswordForm = lazy(() => import('../pages/SignIn/Forms/ForgotPasswordForm'));
const LogInForm = lazy(() => import('../pages/SignIn/Forms/LogInForm'));
const RequestInviteForm = lazy(() => import('../pages/SignIn/Forms/RequestInviteForm'));
const ResetPasswordForm = lazy(() => import('../pages/SignIn/Forms/ResetPasswordForm'));
const SignIn = lazy(() => import('../pages/SignIn/SignIn'));
const ActivateAccountForm = lazy(() => import('../pages/SignIn/Forms/ActivateAccountForm'));
const StubDummyComponent = lazy(() => import('../reusable/StubDummyComponent'));
const LandPartners = lazy(() => import('../pages/LandPartners/LandPartners'));
const LandPartnerProfilesPage = lazy(() => import('../pages/LandPartners/LandPartnerProfilesPage'));
const LandPartnersDetailPage = lazy(() => import('../pages/LandPartners/LandPartnersDetailPage'));
const NotificationsList = lazy(() => import('../features/Notifications/NotificationsList'));
const NotificationsSettings = lazy(
    () => import('../pages/Settings/Notifications/NotificationsSettings'),
);
const AllProfiles = lazy(() => import('../pages/AllProfiles/AllProfiles'));
const AllProfilesDetailsPage = lazy(() => import('../pages/AllProfiles/AllProfilesDetailsPage'));
const LandPartnerWizard = lazy(() => import('../features/CreateLandPartner/LandPartnerWizard'));
const OrganizationWizard = lazy(
    () => import('../pages/Settings/Organizations/AddOrganization/OrganizationWizard'),
);
const AllTasks = lazy(() => import('../pages/Tasks/AllTasks'));
const PaymentOverview = lazy(() => import('../features/PaymentsOverview/PaymentOverview'));
const Payments = lazy(() => import('../pages/Payments/Payments'));
const ExpiredLinkScreen = lazy(() => import('../pages/SignIn/ExpiredLinkScreen'));
const ContactWizard = lazy(() => import('../features/CreateContact/ContactWizzard'));
const GeneralManagerSettings = lazy(() => import('../pages/Settings/GeneralManagerSettings'));
const GeneralManagerSettingsWrapper = lazy(
    () => import('../pages/Settings/GeneralManagerSettingsWrapper'),
);
const ManageLandPartnerSharing = lazy(
    () => import('../pages/Settings/SharingSettings/ManageLandPartnerSharing'),
);
const ArchiveObjects = lazy(() => import('../pages/Settings/ArchiveObjects/ArchiveObjects'));
const SharedDataPreviewPage = lazy(
    () => import('../pages/SharedData/Preview/SharedDataPreviewPage'),
);
const SharedDataMetricsPage = lazy(
    () => import('../pages/SharedData/TemporaryAccess/SharedDataMetricsPage'),
);
const SharedTempDocumentsOnly = lazy(
    () => import('../pages/SharedData/SharedDocumentsOnly/SharedTempDocumentsOnly'),
);
const SharedDataAllPage = lazy(
    () => import('../pages/SharedData/TemporaryAccess/SharedDataAllPage'),
);
const VerifyEmailScreen = lazy(() => import('../pages/SignIn/VerifyEmailScreen'));
const Fields = lazy(() => import('../pages/Fields/Fields'));
const FieldsDetails = lazy(() => import('../features/ViewFieldsDetails/FieldsDetails'));
const Growers = lazy(() => import('../pages/Growers/Growers'));
const EmergencyReadiness = lazy(() => import('../features/Succession/EmergencyReadiness'));
const Services = lazy(() => import('../pages/Services/Services'));
const EmergencyReadinessStepper = lazy(
    () => import('../pages/DisasterPlanning/EmergencyReadinessStepper'),
);
const OtherServices = lazy(() => import('../pages/OtherServices/OtherServices'));
const RedirectPageUploadDocument = lazy(
    () =>
        import(
            '../features/ViewLandPartnerDetails/LandPartnerDocuments/RedirectPageUploadDocument'
        ),
);
const SuccessUploadPage = lazy(
    () => import('../features/ViewLandPartnerDetails/LandPartnerDocuments/SuccessUploadPage'),
);
const QuickBookDataImport = lazy(
    () => import('../features/PaymentsOverview/IntegrationPayment/QuickBookDataImport'),
);
const GuestsDocuments = lazy(() => import('../pages/Guests/GuestsDocuments'));
const Guests = lazy(() => import('../pages/Guests/Guests'));
const LoginGuestForm = lazy(() => import('../pages/Guests/LoginGuestForm'));
const SetPasswordForm = lazy(() => import('../pages/Guests/SetPasswordForm'));
const Reports = lazy(() => import('../features/Reports/Reports'));
const DocusignSuccessPage = lazy(
    () => import('../features/ViewLandPartnerDetails/LandPartnerDocuments/DocusignSuccessPage'),
);
export const RouterConfig = createBrowserRouter([
    {
        path: `${PATHS.auth}/*`,
        Component: SignIn,
        children: [
            { index: true, path: `${PATHS.logIn}`, Component: LogInForm },
            { path: `${PATHS.requestInvite}`, Component: RequestInviteForm },
            { path: `${PATHS.resetPassword}`, Component: ResetPasswordForm },
            { path: `${PATHS.forgotPassword}`, Component: ForgotPasswordForm },
            { path: `${PATHS.userActivation}`, Component: ActivateAccountForm },
        ],
    },
    {
        path: `guests/*`,
        Component: Guests,
        children: [
            { index: true, path: `login`, Component: LoginGuestForm },
            { index: true, path: `setPassword`, Component: SetPasswordForm },
        ],
    },
    {
        path: `${PATHS.landpartners}/${PATHS.profiles}/docusign/success`,
        Component: DocusignSuccessPage,
    },
    {
        path: `shared/documents/upload`,
        Component: RedirectPageUploadDocument,
    },
    {
        path: `shared/documents`,
        Component: GuestsDocuments,
    },

    {
        path: `${PATHS.shared}/${PATHS.success}`,
        Component: SuccessUploadPage,
    },
    {
        path: `${PATHS.auth}/${PATHS.expiredLink}`,
        Component: ExpiredLinkScreen,
    },
    {
        path: `${PATHS.contact}/${PATHS.verifyEmail}`,
        Component: VerifyEmailScreen,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.metrics}`,
        Component: SharedDataMetricsPage,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.documents}`,
        Component: SharedTempDocumentsOnly,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.all}`,
        Component: SharedDataAllPage,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.preview}`,
        Component: SharedDataPreviewPage,
    },
    {
        path: '/',
        Component: PageWrapper,
        children: [
            { path: `/`, Component: Dashboard },
            { path: `${PATHS.notifications}`, Component: NotificationsList },
            {
                path: `payments/import`,
                Component: QuickBookDataImport,
            },
            { path: `${PATHS.tasks}`, Component: AllTasks },
            {
                path: `${PATHS.myProfile}/*`,
                Component: UserPersonalInfo,
            },
            {
                path: `${PATHS.farmProfile}/*`,
                Component: FarmProfile,
            },
            {
                path: `${PATHS.emergencyReadiness}/*`,
                Component: EmergencyReadinessStepper,
            },
            {
                path: `${PATHS.otherServices}/*`,
                Component: OtherServices,
            },
            {
                path: `${PATHS.landpartners}/*`,
                Component: LandPartners,
                children: [
                    {
                        path: `${PATHS.profiles}`,
                        Component: LandPartnerProfilesPage,
                    },
                    {
                        path: `${PATHS.profiles}/${PATHS.create}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.growers}/${PATHS.create}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.profiles}/:id/${PATHS.update}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.growers}/:id/${PATHS.update}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.profiles}/:id/*`,
                        Component: LandPartnersDetailPage,
                    },
                    {
                        path: `${PATHS.growers}/:id/*`,
                        Component: LandPartnersDetailPage,
                    },
                    {
                        path: `${PATHS.allProfiles}/${PATHS.create}`,
                        Component: ContactWizard,
                    },
                    {
                        path: `${PATHS.allProfiles}/:id/${PATHS.update}`,
                        Component: ContactWizard,
                    },
                    {
                        path: `${PATHS.allProfiles}`,
                        Component: AllProfiles,
                    },
                    {
                        path: `${PATHS.growers}`,
                        Component: Growers,
                    },

                    {
                        path: `${PATHS.allProfiles}/:id/*`,
                        Component: AllProfilesDetailsPage,
                    },

                    {
                        path: `${PATHS.team}`,
                        Component: () => <div>team</div>,
                    },
                ],
            },
            {
                path: `${PATHS.landPortfolio}/*`,
                Component: LandPortfolio,
                children: [
                    {
                        path: `${PATHS.manage}`,
                        Component: ManageLeasesPage,
                    },
                    {
                        path: `${PATHS.fields}`,
                        Component: Fields,
                    },
                    {
                        path: `${PATHS.fields}/:id/*`,
                        Component: FieldsDetails,
                    },
                    {
                        path: `${PATHS.manage}/${PATHS.create}`,
                        Component: LeaseWizard,
                    },
                    {
                        path: `${PATHS.manage}/:id/${PATHS.update}`,
                        Component: LeaseWizard,
                    },

                    {
                        path: `${PATHS.manage}/:id/*`,
                        Component: LeaseDetailPage,
                    },
                    {
                        path: `${PATHS.locations}`,
                        Component: () => <div>locations</div>,
                    },
                    {
                        path: `${PATHS.reports}`,
                        Component: () => <div>reports</div>,
                    },
                ],
            },
            {
                path: `${PATHS.services}/*`,
                Component: Services,
                children: [
                    {
                        path: `${PATHS.emergencyReadiness}`,
                        Component: EmergencyReadiness,
                    },
                    {
                        path: `${PATHS.emergencyReadiness}/${PATHS.create}`,
                        Component: EmergencyReadinessStepper,
                    },
                    {
                        path: `${PATHS.allServices}`,
                        Component: () => <div>all services</div>,
                    },
                ],
            },
            {
                path: `${PATHS.payments}/*`,
                Component: Payments,
                children: [
                    {
                        path: `${PATHS.overview}`,
                        Component: PaymentOverview,
                    },
                    {
                        path: `${PATHS.calculate}`,
                        Component: () => <div>calculate</div>,
                    },
                    {
                        path: `${PATHS.configure}`,
                        Component: () => <div>configure</div>,
                    },
                ],
            },
            {
                path: `${PATHS.communications}/*`,
                Component: Communications,
                children: [
                    {
                        path: `${PATHS.calendar}`,
                        Component: () => <div>calendar</div>,
                    },
                    {
                        path: `${PATHS.taskAndReminders}`,
                        Component: () => <div>taskAndReminders</div>,
                    },
                    {
                        path: `${PATHS.newsletters}`,
                        Component: () => <div>newsletters</div>,
                    },
                    {
                        path: `${PATHS.surveys}`,
                        Component: () => <div>surveys</div>,
                    },
                ],
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/:id/${PATHS.update}`,
                Component: OrganizationWizard,
            },
            {
                path: `${PATHS.settings}/*`,
                Component: Settings,
                children: [
                    {
                        path: `${PATHS.reports}/*`,
                        Component: Reports,
                    },
                    {
                        path: `${PATHS.orgAndUsers}/*`,
                        Component: OrganizationsAndUsers,
                    },
                    {
                        path: `${PATHS.viewsSettings}`,
                        Component: NotificationsAndViews,
                    },
                    {
                        path: `${PATHS.notificationsSettings}`,
                        Component: NotificationsSettings,
                    },
                ],
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/*`,
                Component: Organizations,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/${PATHS.create}`,
                Component: OrganizationWizard,
            },

            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.roles}/*`,
                Component: Roles,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.users}/*`,
                Component: Users,
            },
            {
                path: `${PATHS.generalManagerSettings.root}/*`,
                Component: GeneralManagerSettingsWrapper,
                children: [
                    {
                        path: `*`,
                        Component: GeneralManagerSettings,
                    },
                    {
                        path: `${PATHS.generalManagerSettings.landPartnerSharing}`,
                        Component: ManageLandPartnerSharing,
                    },
                    {
                        path: `${PATHS.archive}`,
                        Component: ArchiveObjects,
                    },
                    {
                        path: `${PATHS.integrations}`,
                        Component: Integrations,
                    },
                ],
            },
        ],
        errorElement: <StubDummyComponent title={'Something happened'} />,
    },
]);
