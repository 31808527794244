export default {
    auth: 'auth',
    logIn: 'logIn',
    requestInvite: 'requestInvite',
    forgotPassword: 'forgotPassword',
    resetPassword: 'new-password',
    userActivation: 'activation',
    dashboard: '/',
    landowners: 'landowners',
    landpartners: 'landpartners',
    profiles: 'profiles',
    allProfiles: 'allProfiles',
    contacts: 'contacts',
    contact: 'contact',
    team: 'team',
    myProfile: 'myProfile',
    preferences: 'preferences',

    landPortfolio: 'landPortfolio',
    manage: 'manage',
    create: 'create',
    viewDetails: 'viewDetails',
    maps: 'maps',
    payments: 'payments',
    activity: 'activity',
    relationship: 'relationship',
    documents: 'documents',
    goals: 'goals',

    locations: 'locations',
    reports: 'reports',
    history: 'history',
    overview: 'overview',
    calculate: 'calculate',
    configure: 'configure',

    communications: 'communications',
    calendar: 'calendar',
    taskAndReminders: 'taskAndReminders',
    newsletters: 'newsletters',
    surveys: 'surveys',
    update: 'update',
    settings: 'settings',
    organizations: 'organizations',
    roles: 'roles',
    allRoles: 'allRoles',
    billing: 'billing',
    users: 'users',
    groups: 'groups',
    integrations: 'integrations',
    viewsSettings: 'views-settings',
    notificationsSettings: 'notifications-settings',
    orgAndUsers: 'orgAndUsers',
    fields: 'fields',
    notifications: 'notifications',
    tasks: 'tasks',
    notes: 'notes',
    archive: 'archive',
    expiredLink: 'expiredLink',
    generalManagerSettings: {
        root: 'generalManagerSettings',
        landPartnerSharing: 'landPartnerSharing',
    },
    verifyEmail: 'verify-email',
    sharedData: {
        root: 'shared-data',
        preview: 'preview',
        documents: 'documents',
        metrics: 'metrics',
        all: 'all',
    },
    manageRoles: 'manageRoles',
    compareRoles: 'compareRoles',
    farmProfile: 'farmProfile',
    planning: 'planning',
    leases: 'leases',
    growers: 'growers',
    services: 'services',
    allServices: 'allServices',
    emergencyReadiness: 'emergencyReadiness',
    otherServices: 'otherServices',
    success: 'success',
    shared: 'shared',
    estatePlanning: 'estatePlanning',
    businessPlanning: 'businessPlanning',
    successionPlanning: 'successionPlanning',
    financialAutonomy: 'financialAutonomy',
};
