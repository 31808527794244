import React from 'react';
import { Form } from 'react-final-form';

import { Button, Divider, Grid } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import useAvatar from '../../hooks/useAvatar';

import { useUpdateAccountMutation } from '../../api/users/accountAPI';
import SubmitButton from '../../design/Buttons/SubmitButton';
import customToastWithAlert from '../../design/CustomToastWithAlert';
import GenericFormInput from '../../design/FormFields/GenericFormInput';
import theme from '../../styles/theme';
import { dateConverterFromUTC } from '../../utils/dateFormat';
import { SocialMedia } from '../ViewLandPartnerDetails/LandPartnerDetails';
import { Account, KeyDates } from './UserProfile';
import { userProfileFormConfig } from './userProfileFormConfig';

export interface Profile {
    firstName: string;
    lastName: string;
    primaryEmail: string;
    secondaryEmail?: string;
    phoneNumber?: string;
    organizationId?: string;
    roleId?: string;
    profilePhoto?: string;
    socialMedia?: SocialMedia;
    keyDates?: KeyDates;
    birthDate?: string;
    relationshipStartDate?: string;
}

interface OwnProps {
    data: Account;
    onClick: () => void;
}

export default function UserProfileForm({ data: initData, onClick }: OwnProps) {
    const { classes } = useStyles();

    const [updateAccount, { data: updatedData, isLoading: isProfileDataUpdating }] =
        useUpdateAccountMutation();

    const data = updatedData || initData;

    const { createFile } = useAvatar();

    const formData = {
        ...data,
        organization: data.organization?.name,
        role: data.role?.name,
        profilePhoto:
            data.profilePhoto &&
            createFile(
                data.profilePhoto.profilePhoto,
                data.profilePhoto.fileName,
                data.profilePhoto.fileType,
            ),
        keyDates: {
            birthDate: data.keyDates?.birthDate && new Date(data.keyDates?.birthDate),
            relationshipStartDate:
                data.keyDates?.relationshipStartDate &&
                new Date(data.keyDates?.relationshipStartDate),
        },
    };

    function handleSubmit(value: Profile) {
        updateAccount({
            ...value,
            keyDates: {
                birthDate:
                    value.keyDates?.birthDate && dateConverterFromUTC(value.keyDates?.birthDate),
                relationshipStartDate:
                    value.keyDates?.relationshipStartDate &&
                    dateConverterFromUTC(value.keyDates?.relationshipStartDate),
            },
        })
            .unwrap()
            .then(() => {
                return customToastWithAlert({
                    type: 'success',
                    message: 'User was updated successfully',
                });
            })
            .catch((error) => {
                return customToastWithAlert({
                    type: 'error',
                    message: error?.data?.description || 'Something went wrong',
                });
            });
    }

    return (
        <div className={classes.formContainer}>
            <Form onSubmit={handleSubmit} initialValues={formData}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {userProfileFormConfig.map(({ formField, renderProps }) => {
                                return (
                                    <GenericFormInput
                                        key={formField.name}
                                        {...formField}
                                        {...renderProps}
                                    />
                                );
                            })}
                        </Grid>
                        <Divider orientation="horizontal" sx={{ paddingTop: theme.spacing(2) }} />
                        <div className={classes.footer}>
                            <Button onClick={onClick} color={'secondary'}>
                                Cancel
                            </Button>
                            <SubmitButton isLoading={isProfileDataUpdating} text={'Save'} />
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
}

const useStyles = makeStyles()(() => ({
    formContainer: {
        marginTop: 0,
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.neutralWhite,
    },
    footer: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
}));
